import React from "react"
import PropTypes from "prop-types"
import indexStyles from "../../styles/index.module.css"

export const SectionTitleFn = props => (
  <div className={indexStyles.skillDesc}>
    <h1 className={indexStyles.sectionTitle}>{props.title}</h1>
    <p className={indexStyles.sectionDesc}>{props.desc}</p>
  </div>
)

SectionTitleFn.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

export const SectionTitle = SectionTitleFn
