import PropTypes from "prop-types"
import React from "react"
import { FaBars } from "react-icons/fa"
import "./styles.css"

const addResponsiveness = () => {
  console.log("called")
  var x = document.getElementById("myTopnav")
  if (x.className === "topnav") {
    x.className += " responsive"
  } else {
    x.className = "topnav"
  }
}

const Header = () => {
  return (
    <header>
      <div className="topnav" id="myTopnav">
        <a
          href="javascript:void(0);"
          role="button"
          className="icon"
          onClick={() => addResponsiveness()}
        >
          <FaBars />
        </a>
        <a href="/">About Me</a>
        <a href="#skills">Skills</a>
        <a href="#services">Services</a>
        <a href="#experiences">Experience</a>
        <a href="#projects">Projects</a>
        <a href="#education">Education</a>
        <a href="#contacts">Contacts</a>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
