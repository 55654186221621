const education = [
  {
    institution: "Heritage Institute of Technology",
    location: "Kolkata, West Bengal",
    degree: `B.Tech (Information Technology)`,
    duration: `2012-2016`,
    desc: `Pursued Bachelors in Technology with specialization in Information Technology during the period 2012-2016.`,
  },
  {
    institution: "Delhi Public School",
    location: "Ranchi, Jharkhand",
    degree: "Senior Secondary (Computer Science)",
    duration: `2009-2011`,
    desc: `Had Maths, Science and Computers as my majors during the period 2009-2011.`,
  },
]

const experiences = [
  {
    company: "Freelance",
    logoImage: "freelancer",
    link: "https://geekyants.com/",
    designation: "Freelancer - Web/Mobile App Developer",
    duration: "Feb 2019 - Present",
    desc: `Self-employed, work for myself, rather than for a company.
     I take on contract work for companies and organizations usually on hourly basis (can be fixed price at times), based all across the globe. Have developed and delivered multiple mobile and web applications.`,
  },

  {
    company: "Geekyants",
    duration: "Nov 2016 - Feb 2019",
    designation: "Full Stack Developer",
    logoImage: "geekyants",
    link: "https://geekyants.com/",
    desc: `Worked on developing mobile and web applications with a team of
    developers and delivered quality applications. Adjusted software parameters to boost performance and incorporate
    new features. Maintained existing applications, designed and delivered new
    applications as well.`,
  },
  {
    company: "Gsynergy",
    designation: "Full Stack Developer",
    duration: "Feb 2019 - April 2020",
    logoImage: "gsynergyicon",
    link: "https://www.linkedin.com/company/gsynergy/",
    desc: `Worked as a freelance contractor. Delivered performance-driven and user-centric mobile application that met all
    business requirements.
    Collaborated on all stages of systems development lifecycle, from
    requirement gathering to production releases`,
  },
  {
    company: "Edureka",
    duration: "Aug 2016 - Oct 2016",

    designation: "Tech Support Engineer",
    logoImage: "edureka",
    link: "https://www.edureka.co/",
    desc: `Diagnosed and troubleshooted coding problems and helped customers install applications and programs to understand the course properly. 
    Resolved multiple issues, configured operating systems and remote desktop connections to provide immediate support.`,
  },
]

const projects = [
  {
    title: "Findz",
    image: "findz",
    link: "https://www.findz.app/",
    desc: `An offline-first app that lets users to collect products, record additional information about those products, collaborate with people in groups
    and search for them based on the product attributes`,
  },
  {
    title: "Bonliva",
    image: "bonliva",
    link: "https://bonliva.se",
    desc: `Mobile and web app for registered hospitals and individuals to book available doctors and schedule appointments`,
  },
  {
    title: "Medlife",
    image: "medlife",
    link:
      "https://play.google.com/store/apps/details?id=com.medlife.customer&hl=en",
    desc: `A mobile app for ordering medicines, getting appointments and prescriptions from doctors.
    `,
  },
  {
    title: "Areena",
    image: "areena",
    desc: `A mobile app for registered NGOs, organizations and social activists to schedule and organize events and post feed, coupled with chat feature`,
  },
  {
    title: "Forbes Mobile",
    image: "forbes",
    desc: `Mobile and web apps with video player along with live streaming, coupled with in-app purchases`,
  },
  {
    title: "Cirkle",
    image: "cirkle",
    desc: `A social app to promote brands by posting their pictures on social platforms (Instagram, Facebook and Twitter) and earn discounts on each like and comment.`,
  },
  {
    title: "Foode",
    image: "foode",
    desc: `A restaurant discovery and rating app that helps to find top restaurants nearby you, share your experience by posting feeds, follow/unfollow users coupled with one-to-one chat feature.`,
  },
  {
    title: "Messaging App",
    image: "messaging",
    desc: `A one-to-one, realtime WhatsApp like chat app, uses React-Native, Mobx, Feathers.js, MongoDB`,
  },
]

const allServices = [
  {
    imgSrc: "ui",
    title: "UI/UX DESIGN",
    desc: `Cool and responsive UI designs for phone, tablets and websites maximising the use of reusable components.`,
  },
  {
    imgSrc: "rn",
    title: "React / React Native",
    desc: `Stable, highly performant mobile and web applications coupled with features like push notification, chats, collaboration and many more.`,
  },
  {
    imgSrc: "redux",
    title: "Redux / Mobx",
    desc: `Centralised application state management to enable powerful capabilities and consistent app behavior.`,
  },
  {
    imgSrc: "node",
    title: "NodeJS",
    desc: `Javascript for Server side scripts, backend APIs, firebase cloud functions because why not!`,
  },
  {
    imgSrc: "es6",
    title: "ES6+",
    desc: `Write less, do more - new syntax and new awesome features to make code more modern and more readable.`,
  },
  {
    imgSrc: "tdd",
    title: "TDD",
    desc: `Snapshot, unit and integration tests for mobile/web applications to ensure lesser bugs and more stability leading to good user experience.`,
  },
]

const skills = [
  {
    title: "JavaScript",
    rating: "80%",
  },
  {
    title: "React",
    rating: "90%",
  },
  {
    title: "Node",
    rating: "70%",
  },
  {
    title: "TypeScript",
    rating: "85%",
  },
  {
    title: "React Native",
    rating: "90%",
  },
  {
    title: "Firebase",
    rating: "90%",
  },
]

export const data = {
  skills,
  allServices,
  projects,
  education,
  experiences,
}
