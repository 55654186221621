import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import indexStyles from "../../styles/index.module.css"
import { SectionTitle } from "../SectionTitle"
import { data } from "../../data"

export const ProjectsFn = () => {
  const imagesData = useStaticQuery(graphql`
    query ProjQuery {
      findz: file(relativePath: { eq: "findz.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonliva: file(relativePath: { eq: "bonliva.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      medlife: file(relativePath: { eq: "medlife.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areena: file(relativePath: { eq: "areena.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      forbes: file(relativePath: { eq: "forbes.jpeg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cirkle: file(relativePath: { eq: "cirkle.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foode: file(relativePath: { eq: "foode.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      messaging: file(relativePath: { eq: "messaging.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { projects } = data
  return (
    <div className={indexStyles.section} id="projects">
      <SectionTitle
        title="Projects"
        desc={`I have designed, developed and deployed multiple mobile applications and
        websites over a period of 4 years. I have contributed in both front-end
        as well as back-end development. A few of them are in production. The
        links are attached with the project description below:
      `}
      />
      <div className={indexStyles.projectsRow}>
        {projects.map(item => (
          <div className={indexStyles.projectsBox} key={item.image}>
            <Img
              fadeIn={true}
              alt={item.image}
              objectFit="contain"
              className={indexStyles.projectImg}
              fluid={imagesData[item.image].childImageSharp.fluid}
            />
            <div
              className={indexStyles.projDesc}
              style={{ cursor: item.link ? "pointer" : undefined }}
              onClick={() => (item.link ? window.open(item.link) : {})}
            >
              <h3>{item.title}</h3>
              {item.desc}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Projects = ProjectsFn
