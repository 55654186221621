import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import indexStyles from "../../styles/index.module.css"
import { SectionTitle } from "../SectionTitle"
import { data } from "../../data"

export const ServicesFn = () => {
  const imagesData = useStaticQuery(graphql`
    query ServiceQuery {
      ui: file(relativePath: { eq: "ui.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rn: file(relativePath: { eq: "rn.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      redux: file(relativePath: { eq: "redux.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      node: file(relativePath: { eq: "node.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      es6: file(relativePath: { eq: "es6.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tdd: file(relativePath: { eq: "tdd.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={indexStyles.section}
      style={{ backgroundColor: "#f3f5ff" }}
      id="services"
    >
      <SectionTitle
        title="What I do"
        desc={`I’m a full-stack web/mobile application developer and I have been
          working in React/React Native for 4+ years. I’ve mostly worked with
          startups and have been freelancing since last 1.5 years.
        `}
      />
      <div className={indexStyles.techBox}>
        {data.allServices.map(service => (
          <div className={indexStyles.column} key={service.imgSrc}>
            <Img
              fluid={imagesData[service.imgSrc].childImageSharp.fluid}
              alt={service.imgSrc}
              fadeIn={true}
              style={{
                width: "50px",
                height: "50px",
                marginBottom: "20px",
              }}
              objectFit="contain"
            />
            <p className={indexStyles.boxTitle}>{service.title}</p>
            {service.desc}
          </div>
        ))}
      </div>
    </div>
  )
}

export const Services = ServicesFn
