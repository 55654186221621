import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import indexStyles from "../../styles/index.module.css"
import { FaTwitter, FaLinkedin, FaMedium, FaGithub } from "react-icons/fa"

export const AboutMeFn = () => {
  const imagesData = useStaticQuery(graphql`
    query AboutQuery {
      profilePic: file(relativePath: { eq: "profilePic.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={indexStyles.firstSection}>
      <div className={indexStyles.aboutMe}>
        <div className={indexStyles.myDesc}>
          <h1 className={indexStyles.sectionTitle}>Hi, I'm Shruti</h1>
          <h2>Full Stack Developer</h2>
          <p className={indexStyles.sectionDesc}>
            I'm an experienced Mobile/Web Application Developer with a
            demonstrated history of working in the information technology and
            services industry. I'm skilled in React Native, ReactJS, NodeJS and
            Firebase.
          </p>
          <div className={indexStyles.row}>
            <div
              className={indexStyles.socialIcon}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/shruti-shankar-3314b562/"
                )
              }
            >
              <FaLinkedin color="#fff" />
            </div>
            <div
              className={indexStyles.socialIcon}
              onClick={() => window.open("https://github.com/shrutishankar28")}
            >
              <FaGithub />
            </div>
            <div
              className={indexStyles.socialIcon}
              href="https://medium.com/@shrutishankar_57908"
              onClick={() =>
                window.open("https://medium.com/@shrutishankar_57908")
              }
            >
              <FaMedium />
            </div>
            <div
              className={indexStyles.socialIcon}
              href="https://twitter.com/shrutishankar28"
              onClick={() => window.open("https://twitter.com/shrutishankar28")}
            >
              <FaTwitter />
            </div>
          </div>
        </div>
        <div className={indexStyles.myPic}>
          <Img
            alt=""
            fadeIn={true}
            objectFit="contain"
            className={indexStyles.profilePic}
            fluid={imagesData.profilePic.childImageSharp.fluid}
          />
        </div>
      </div>
    </div>
  )
}

export const AboutMe = AboutMeFn
