import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import indexStyles from "../../styles/index.module.css"
import { SectionTitle } from "../SectionTitle"
import { data } from "../../data"

export const ExperiencesFn = () => {
  const imagesData = useStaticQuery(graphql`
    query ExpQuery {
      freelancer: file(relativePath: { eq: "freelancer.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      geekyants: file(relativePath: { eq: "geekyants.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gsynergyicon: file(relativePath: { eq: "gsynergyicon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      edureka: file(relativePath: { eq: "edureka.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const ExpImage = props => (
    <Img
      fluid={imagesData[props.imagName].childImageSharp.fluid}
      alt={props.imagName}
      fadeIn={true}
      style={{
        width: props.width || "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
      objectFit="contain"
    />
  )

  const { experiences } = data
  return (
    <div className={indexStyles.section} id="experiences">
      <SectionTitle
        title="Experience"
        desc={` I started my career as a Tech Support Engg and later switched to
      application development. I have worked with a few startups based in
      Bangalore and US. Got bored of daily travels and now I'm freelancing.
      `}
      />
      <div className={indexStyles.experienceContainer}>
        <div className={indexStyles.experienceItem}>
          <div className={indexStyles.expBox}>
            <ExpImage imagName={experiences[0].logoImage} />
            <h3>{experiences[0].designation}</h3>
            <p className={indexStyles.expDuration}>
              ({experiences[0].duration})
            </p>
            {experiences[0].desc}
          </div>
          <div className={`${indexStyles.expBox} ${indexStyles.expGap}`}>
            <ExpImage imagName={experiences[1].logoImage} />
            <h3>{experiences[1].designation}</h3>
            <p className={indexStyles.expDuration}>
              ({experiences[1].duration})
            </p>
            {experiences[1].desc}
          </div>
        </div>
        <div style={{ width: "10%" }} />
        <div className={`${indexStyles.experienceItem} ${indexStyles.expGap}`}>
          <div className={indexStyles.expBox}>
            <ExpImage imagName={experiences[2].logoImage} width={"100px"} />
            <h3>{experiences[2].designation}</h3>
            <p className={indexStyles.expDuration}>
              ({experiences[2].duration})
            </p>
            {experiences[2].desc}
          </div>
          <div className={`${indexStyles.expBox} ${indexStyles.expGap}`}>
            <ExpImage imagName={experiences[3].logoImage} />
            <h3>{experiences[3].designation}</h3>
            <p className={indexStyles.expDuration}>
              ({experiences[3].duration})
            </p>
            {experiences[3].desc}
          </div>
        </div>
      </div>
    </div>
  )
}

export const Experiences = ExperiencesFn
